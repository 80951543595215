@import "../../styles/abstracts/variables";

.login {
  top: 50%;
  transform: translateY(-50%);
  width: 400px;
  margin: 0 auto;
  padding: 50px;
  border-radius: 5px;
  background-color: transparent;
  border: 2px solid $color-slate-700;
  color: #fff;
  text-align: center;
  background-color: $color-slate-800;

  &__heading {
    font-size: 25px;
    margin-bottom: 10px;
  }

  &__heading-info {
    font-size: 12px;
    margin-bottom: 16px;
    color: $color-slate-400;
  }

  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 200px;
    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $color-slate-400;
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $color-slate-400;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $color-slate-400;
    }

    &__input {
      width: 100%;
      padding: 10px;
      margin-bottom: 20px;
      background: $color-slate-700;
      color: #fff;
      border: 1px solid $color-slate-700;
      outline: none;
    }

    &__btn {
      display: block;
      width: 80%;
      font-size: 16px;
      padding: 10px;
      margin-top: 12px;
      background-color: $color-teal-400;
      color: #fff;
      border: none;
      border-radius: 2px;
      font-size: 14px;

      box-shadow: 0px 2px 5px 0px $color-teal-600;
      transition: all 0.2s ease-in-out;
      outline: none;
      cursor: pointer;

      &:hover {
        transform: scale(1.001);
        background-color: $color-teal-500;
      }
      &:active {
        transform: scale(0.95);
      }
    }

    &__message {
      // &--loading {
      //   color: $color-slate-400;
      // }
      &--error {
        color: $color-red-500;
        position: absolute;
        top: 59.5%;
        left: 13%;
        font-size: 12px;
      }
    }
  }
}
