@import "../../styles/abstracts/variables";
.mainView {
  display: flex;
  max-height: 100%;
  height: 100%;
  background-color: transparent;

  @media (max-width: 600px) {
    flex-direction: column;
  }
}
