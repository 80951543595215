@import "../../styles/abstracts/variables";

iframe {
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  overflow: auto;
}

.iframe-container {
  position: relative;
  display: inline-block;
  height: calc(100vh - 150px);
  width: 100%;
  min-height: 100px;
}

.back__btn {
  margin-top: 40px;
  padding: 8px 24px 8px 24px;
  background-color: $color-slate-600;
  border: 0px;
  color: $color-slate-50;
  border-radius: 6px;
  display: flex;
}

.small-icon {
  width: 16px;
  height: 16px;
  margin-right: 2px;
}
