html {
  box-sizing: border-box;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  min-width: 320px;
  // overflow: hidden;
  max-width: 1024px;
  margin: 0 auto;
  padding: 0;
  color: #fff;
  background-color: $color-slate-900;
}

#root {
  height: 100vh;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul,
li {
  margin: 0;
  padding: 0;
}

div[tabindex="-1"] {
  height: 100%;
}
