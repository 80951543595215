@import "../../styles/abstracts/variables";

.btn {
  min-width: 45px;
  height: 40px;
  padding: 6px;
  background-color: $color-teal-500;
  color: #fff;
  border: none;
  border-radius: 2px;
  outline: none;
  cursor: pointer;

  &.active {
    background-color: $color-teal-600;
  }
}
