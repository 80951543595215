// colors
$color-primary: rgb(23, 25, 29);
$color-s-orange: #f8b74f;
$color-s-grey: #3d424b;
$color-teal-400: #2dd4bf;
$color-teal-500: #14b8a6;
$color-teal-600: #0d9488;
$color-teal-700: #0e7490;
$color-teal-800: #155e75;

$color-red-700: #b91c1c;

$color-slate-50: #f8fafc;
$color-slate-100: #f1f5f9;
$color-slate-200: #e2e8f0;
$color-slate-300: #cbd5e1;
$color-slate-400: #94a3b8;
$color-slate-500: #64748b;
$color-slate-600: #475569;
$color-slate-700: #334155;
$color-slate-800: #1e293b;
$color-slate-900: #0f172a;

$color-red-500: #ef4444;

// $color-s-light-grey: #7b7c82;
$color-s-light-grey: #515163;
$color-s-dark-grey: linear-gradient(to left, #2d2f34, #202227);

//simulate iframe
$main: #252525;
$main-light: #7f7f7f;
$simulate: #f6b52d;
$simulate-dark-green: #0d7b3c;
$simulate-light-green: #12a953;
