@import "../../styles/abstracts/mixins";
@import "../../styles/abstracts/variables";

.big-icon {
  width: 100px;
  height: 100px;
  color: $color-slate-800;
}

.no-data {
  color: $color-slate-500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.betslip {
  @include flexColumn;

  @media (max-width: 600px) {
    width: 100%;
    max-height: 55%;
    height: 55%;
  }

  &__heading {
    @include flexHeading;
    border-left: 1px solid $color-slate-600;
    background-color: $color-slate-700;
    border-radius: 0px 3px 0px 0px;
  }

  &__footer {
    margin: 10px;

    @media (max-width: 600px) {
      margin: 5px;
    }
    
  }

  .scroll-wrapper {
    overflow: auto;
    margin-bottom: auto;
  }

  &__item {
    @include flexCenter(center, space-between, flex);
    font-size: 12px;
    border-radius: 2px;
    // margin-left: 4px;
    margin-top: 2px;
    padding: 10px 10px 10px 8px;
    background: $color-slate-800;
    color: $color-slate-400;
    border: 1px solid $color-slate-700;
    cursor: pointer;

    .selection-teams {
      color: $color-slate-300;
    }
    .selection-market {
      color: $color-slate-500;
      font-weight: bold;
    }

    .odd {
      color: $color-teal-400;
      margin: 0 15px;
    }
    .remove-btn {
      width: 20px;
      height: 20px;
      font-size: 8px;
      font-weight: bold;
      color: $color-slate-200;
      background-color: $color-slate-600;
      border-radius: 100%;
      border: none;
      outline: none;
      cursor: pointer;
    }
  }

  .simulate__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    font-size: 16px;
    padding: 10px;
    margin-top: 12px;
    background-color: $color-s-orange;
    color: $color-slate-700;
    font-weight: bold;
    border: none;
    border-radius: 2px;
    font-size: 14px;
    margin: 0 auto;

    box-shadow: 0px 2px 5px 0px $color-s-orange;
    transition: all 0.2s ease-in-out;
    outline: none;
    cursor: pointer;

    &:hover {
      transform: scale(1.001);
      background-color: $color-s-orange;
    }
    &:active {
      transform: scale(0.95);
    }

    @media (max-width: 600px) {
      padding: 5px;
    }
  }

  &__info {
    display: flex;
    width: 100%;
    justify-content: space-between;
    text-align: center;
    margin: 30px 0;

    @media (max-width: 600px) {
      margin: 4px 0;
    }

    .selection {
      // width: 120px;
      flex-grow: 1;
    }

    .totalOdds {
      flex-grow: 1;
    }

    .win {
      flex-grow: 1;
    }

    .title {
      color: $color-slate-600;
      font-size: 12px;
      font-weight: bold;
    }
    .value {
      color: $color-slate-200;
    }
  }

  &__inputStake {
    padding: 6px;
    // margin-bottom: 20px;
    background: $color-slate-700;
    color: $color-slate-200;
    border: 1px solid $color-slate-700;
    outline: none;
  }

  &__input {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__button {
    padding: 5px 10px;
    margin-left: 10px;
    font-size: 16px;
    background-color: $color-slate-700;
    color: #fff;
    border: none;
    border-radius: 2px;
    outline: none;
    cursor: pointer;
  }
}
