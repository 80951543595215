@mixin flexCenter($alight, $justify, $display: flex) {
  display: $display;
  align-items: $alight;
  justify-content: $justify;
}

@mixin flexHeading {
  display: flex;
  align-items: center;
  max-height: 40px;
  padding: 10px 10px 10px 8px;
  background-color: $color-s-grey;
}

@mixin flexColumn {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
