@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins";

.nav {
  @include flexCenter(center, space-between, flex);
  height: 70px;
  margin-right: 15px;
  margin-left: 15px;

  &__link-container {
    display: flex;
    align-items: center;

    @media (max-width: 400px) {
      flex-direction: column;
    }
  }

  &__logo{
    @media (max-width: 400px) {
      display: none;
    }
  }

  &__link {
    font-size: 18px;
    margin-right: 15px;
    margin-left: 5px;
    padding: 10px 0;
    color: $color-slate-300;
    text-decoration: none;
    transition: all 0.1s ease-in-out;
    cursor: pointer;

    &:hover {
      border-bottom: 3px solid #ccc;
    }

    @media (max-width: 600px) {
      padding: 5px 0;
    }
  }

  &__balance {
    color: $color-slate-200;
    padding: 5px 0;
    text-align: right;

    .title {
      color: $color-slate-500;
      font-weight: bold;
    }
  }
}

.sim-toggle-container input {
  position: absolute;
  opacity: 0;
  height: 19px;
  width: 137px;
  z-index: 10;
}

.sim-toggle-container {
  display: block;
  position: relative;
  user-select: none;
  width: 137px;
  height: 20px;
  border-radius: 14px;
  border: solid 1px $color-slate-700;
  background-color: $color-slate-800;
  z-index: 0;
}

.sim-toggle-slider {
  position: absolute;
  cursor: pointer;
  width: 54px;
  height: 20px;
  border-radius: 11.5px;
  box-shadow: 0 3px 1px 0 rgba(0, 0, 0, 0.06), 0 3px 8px 0 rgba(0, 0, 0, 0.15);
  background-color: $color-teal-500;
  border: $color-teal-700 1px solid;
  z-index: 1;
  top: -1px;
  left: -1px;
  transform: translateX(0px);
  transition: all 200ms cubic-bezier(0.76, 0.26, 0.86, 1.35);
}

.sim-display {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 5;
}

.sim-real {
  width: 40%;
  height: 100%;
  font-family: sans-serif;
  font-size: 10px;
  font-weight: 500;
  color: #fff;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: color 150ms cubic-bezier(0.18, -0.12, 0.51, 0.69);
}

.sim-logo {
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  fill: $main-light;
  transition: fill 150ms cubic-bezier(0.18, -0.12, 0.51, 0.69);
}

/******* SIMULATE TOGGLE INTERACTIVITY *******/

.sim-toggle-container input:checked ~ .sim-toggle-slider {
  transform: translateX(51px);
  background-color: $simulate;
  border: $simulate 1px solid;
  width: 86px;
}

.sim-toggle-container input:checked ~ .sim-display .sim-real {
  color: $main-light;
}

.sim-toggle-container input:checked ~ .sim-display .sim-logo {
  fill: $main;
}
