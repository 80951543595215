@import "../../styles/abstracts/mixins";
@import "../../styles/abstracts/variables";

.matchDetails {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__heading {
    @include flexHeading;
    background-color: $color-slate-700;
    border-radius: 3px 0px 3px 0px;

    .back-btn {
      padding: 5px 15px;
      margin-right: 20px;
      background-color: $color-slate-500;
      color: $color-slate-50;
      border: none;
      border-radius: 3px;
      border: 1px solid $color-slate-400;
      outline: none;
      cursor: pointer;
    }
  }

  .scroll-wrapper {
    overflow: scroll;
    height: 100%;
  }

  &__item {
    font-size: 12px;
    background-color: $color-slate-700;
    color: #fff;
    border: 1px solid $color-slate-700;
    list-style-type: none;
    // cursor: pointer;

    .market-name {
      color: $color-slate-300;
      padding: 10px;
      background: $color-slate-800;
      font-weight: bold;
      display: flex;
      align-items: center;

      .expand-btn {
        width: 20px;
        height: 20px;
        background: transparent;
        margin-left: auto;
        cursor: pointer;
        border: none;
        color: $color-teal-500;
      }
    }

    .market-desc {
      background-color: $color-slate-500;
      padding: 25px 10px;

      ul {
        margin-top: 0.5em;
        margin-left: 1.5em;
      }

      li {
        margin-bottom: 0.5em;
      }
    }

    .item-wrapper {
      display: flex;
      flex-wrap: wrap;
      padding: 10px 0;

      position: relative;
      z-index: 3;
      background: $color-slate-700;
    }

    .outcome-box {
      @include flexCenter(center, space-between, flex);
      flex-basis: 33.333333%;
      margin: 5px 0;
      padding: 0 10px;

      .outcome {
        width: 100%;
        padding: 0 5px;
        text-align: center;
      }
    }
  }
}
