@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins";

.sportsbook {
  @include flexColumn;

  @media (max-width: 600px) {
    width: 100%;
    max-height: 45%;
    height: 45%;
    margin-bottom: 4px;
  }

  &__spinner {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__heading {
    @include flexHeading;
    background-color: $color-slate-700;
    border-radius: 3px 0px 0px 0px;
  }

  .scroll-wrapper {
    overflow: auto;
  }

  &__item {
    display: flex;
    font-size: 12px;
    margin: 2px 0;
    padding: 10px 10px 10px 8px;
    background-color: $color-slate-800;
    overflow: hidden;
    color: #fff;
    border-radius: 2px;
    border: 1px solid $color-slate-700;
    cursor: pointer;
    &:hover {
      background-color: $color-slate-700;
    }

    .matchInfo {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .matchInfo-market {
      color: $color-slate-500;
      font-weight: bold;
    }

    .matchInfo-teams {
      color: $color-slate-200;
    }

    .odds {
      text-align: center;

      &:nth-child(2) {
        margin: 0 2px;
      }

      .outcomes {
        margin-bottom: 5px;
      }
    }
  }
}
