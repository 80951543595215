.loading-container {
    height: 100vh;
    width: 1024px;
    position: relative;
  
    div {
      width: 70px;
      height: 70px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -35px 0 0 -35px;
    }
  }