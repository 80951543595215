@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins";

.transaction {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__heading {
    @include flexHeading;
    background-color: $color-slate-700;
    border-radius: 3px 3px 0px 0px;
  }

  &__spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &__pagination {
    @include flexCenter(center, space-around, flex);
    // background-color: $color-s-light-grey;
    background-color: $color-slate-700;
    border-radius: 3px 3px 0px 0px;
    color: $color-slate-300;

    .button {
      padding: 4px 45px;
      background-color: $color-teal-700;
      color: #fff;
      border-radius: 2px;
      border: none;
      // opacity: 0.6;
      outline: none;
      cursor: pointer;

      &:disabled {
        opacity: 0.3;
      }
    }

    .page-number {
      height: 100%;
      padding: 10px 20px;
      color: $color-slate-100;
      border-right: 1px solid $color-slate-600;
      border-left: 1px solid $color-slate-600;
    }
  }

  &__item {
    background-color: $color-slate-800;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 4px;
    padding: 10px 10px 10px 8px;
    border: 1px solid $color-slate-600;
    border-radius: 3px;

    .title {
      margin-right: 4px;
      font-size: 16px;
      font-weight: bold;
      color: $color-slate-500;
    }

    .value {
      color: $color-slate-100;
    }
  }

  .scroll-wrapper {
    overflow: auto;
    width: 100%;
    margin-bottom: auto;
  }
}
